const Images = {
    logo: require('./images/logo.png'),
    // updtae: require('./images/update/update.json'),
    // png: require('./images/docs/defaultImage.png'),
    // group: require('./images/user/userGroup1.png'),
    // userSingle: require('./images/user/userSinge1.png'),
    // postIcon: require('./images/board/postIcon.png'),
    // write: require('./images/board/wirit.png'),
    // IconBell: require('./images/tab/bell.png'),
    // IconBellActive: require('./images/tab/bellActive.png'),
    // IconHome: require('./images/tab/home.png'),
    // IconHomeActive: require('./images/tab/homeActive.png'),
    // IconAcademics: require('./images/tab/graduationCap.png'),
    // IconAcademicsActive: require('./images/tab/graduationCapActive.png'),
    IconChat: require('./images/icons/chat.png'),
    IconUser: require('./images/icons/user.png'),
    IconEdit: require('./images/icons/fileEdit.png'),
    IconSettings: require('./images/icons/settings.png'),
    IconHeart: require('./images/icons/heart.png'),
    IconAdd: require('./images/icons/add.png'),
    IconJoin: require('./images/icons/search.png'),
    IconExtraOptions: require('./images/icons/options.png'),
    IconVote: require('./images/icons/up.png'),
    IconFilledVote: require('./images/icons/upFilled.png'),
    IconComment: require('./images/icons/comments.png'),
    IconSubject: require('./images/icons/subject.png'),
    IconDoc: require('./images/icons/document.png'),
    IconNote: require('./images/icons/note.png'),
    IconEvent: require('./images/icons/event.png'),
    IconCurriculam: require('./images/icons/curriculam.png'),
    IconTimetable: require('./images/icons/timetable.png'),
    IconArrow: require('./images/icons/arrow.png'),
    IconClassmates: require('./images/icons/users.png'),
    IconActivity: require('./images/icons/activity.png'),
    IconAccount: require('./images/icons/account.png'),
    IconTheme: require('./images/icons/theme.png'),
    IconClose: require('./images/icons/close.png'),
    IconHelp: require('./images/icons/help.png'),
    IconReport: require('./images/icons/report.png'),
    IconAddUser: require('./images/icons/userAdd.png'),
    IconCamera: require('./images/icons/camera.png'),
    IconSignout: require('./images/icons/signout.png'),
    IconTrash: require('./images/icons/trash.png'),
    IconPencil: require('./images/icons/pencil.png'),
    IconQRCode: require('./images/icons/qr.png'),
    IconShare: require('./images/icons/share.png'),
    IconFilter: require('./images/icons/filter.png'),
    IconPlus: require('./images/icons/plus.png'),
    IconEye: require('./images/icons/eye.png'),
    IconEyeCrossed: require('./images/icons/eyeCrossed.png'),
    IconPhone: require('./images/icons/mobile.png'),
    IconCheck: require('./images/icons/check.png'),
    IconCircle: require('./images/icons/circle.png'),
    IconDotCircle: require('./images/icons/dotCircle.png'),
    IconLink: require('./images/icons/link.png'),
    IconAttachment: require('./images/icons/clip.png'),
    IconPicture: require('./images/icons/picture.png'),
    IconWand: require('./images/icons/wand.png'),
    IconSchool: require('./images/icons/school.png'),
    IconLock: require('./images/icons/lock.png'),
    University: require('./images/university.png'),
    BlackLogo: require('./images/black_logo.png'),
    Wirit: require('./images/wirit.png'),
    IconDefaultUser: require('./images/icons/defaultUser.png'),
    IconCheckBoxFilled: require('./images/icons/checkbox.png'),
    IconCheckBox: require('./images/icons/box.png'),
  };
  export default Images;