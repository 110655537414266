import GridContainer from 'components/molecules/GridContainer';
import React from 'react';
import './index.scss';
import { StudentAttendenceData } from 'utils/constants';
import { loadUserFromLocalStorage } from 'utils/commonUtils';
import AttendenceProgress from 'components/atoms/AttendenceProgress';

const StudentAttendence: React.FC = () => {

    const userObject = loadUserFromLocalStorage();
    const user = userObject?.userDetails
    const { attendance } = user || {}
    const { absentSessions, attendancePercentage, attendedSessions, totalSessions } = attendance || {}
    return (
        <GridContainer backButton bgColor={'#f4f4f4'} pageTitle={StudentAttendenceData.YourAttendence} leftGridPartition={3 / 4}>
            <div>
                <div className='student_attendence'>
                    <div className='heading'>
                        {StudentAttendenceData.AttendenceOverview}
                    </div>
                    <div className="wrapper">
                        <div className="container">
                            <div className='count'>{attendedSessions} <span>{StudentAttendenceData.Attended}</span></div>
                            <div className='count'>{absentSessions} <span>{StudentAttendenceData.Missed}</span></div>
                            <div className='count'>{totalSessions} <span>{StudentAttendenceData.Total}</span></div>
                            <div className="required">
                                {StudentAttendenceData.Required}
                            </div>
                        </div>
                        <AttendenceProgress progress={attendancePercentage} />
                    </div>
                </div>
            </div>
        </GridContainer>
    );
};

export default StudentAttendence;