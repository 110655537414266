import React from 'react';
import './index.scss';
import GridContainer from 'components/molecules/GridContainer';
import { IconButton, Typography, Box } from "@mui/material";
import { FaMicrophone, FaVideo, FaStopCircle, FaEllipsisH, FaPhoneAlt } from "react-icons/fa";
import { colors } from 'assets/styles/colors';
import MeetParticipants from 'components/molecules/MeetParticipants';

const participants = [
  { id: 1, name: "Speaker", profileImage: "https://randomuser.me/api/portraits/men/10.jpg", subtitle: "CC/Subtitles: I'm speaking now" },
  { id: 2, name: "Participant 1", profileImage: "https://randomuser.me/api/portraits/women/45.jpg" },
  { id: 3, name: "Participant 2", profileImage: "https://randomuser.me/api/portraits/men/30.jpg" },
  { id: 4, name: "Participant 3", profileImage: "https://randomuser.me/api/portraits/women/60.jpg" },
  { id: 5, name: "Participant 4", profileImage: "https://randomuser.me/api/portraits/men/40.jpg" },
  { id: 6, name: "Participant 5", profileImage: "https://randomuser.me/api/portraits/women/45.jpg" },
  { id: 7, name: "Participant 6", profileImage: "https://randomuser.me/api/portraits/men/30.jpg" },
  { id: 8, name: "Participant 7", profileImage: "https://randomuser.me/api/portraits/women/60.jpg" },
  { id: 9, name: "Participant 8", profileImage: "https://randomuser.me/api/portraits/men/40.jpg" },
];

const Meet: React.FC = () => {
  return (
    <GridContainer right={<MeetParticipants data={participants}/>} bgColor={'#f4f4f4'}  backButton pageTitle={'Meeting'} leftGridPartition={3 / 4}>
     <Box className="meeting-container">
      <Box className="meeting-grid">
        {/* Speaker Section */}
        <Box className="speaker">
          <div className="profile">
          <div className='profile_container'>
            <img
              src={participants[0].profileImage} // Speaker's profile image
              alt="profile"
              className="profile-image"
            />
            </div>
            <Typography variant="subtitle2" className="subtitle">
              {participants[0].subtitle}
            </Typography>
          </div>
        </Box>

        {/* Other Participants - mapped dynamically */}
        {participants.slice(1,6).map((participant, index) => (
          <Box key={participant.id} className="participant">
            <div className="profile">
              <div className='profile_container'>
              <img
                src={participant.profileImage}
                alt="profile"
                className="profile-image"
              />
              </div>
              <Typography className='name' variant="subtitle2">{participant.name}</Typography>
            </div>
          </Box>
        ))}
      </Box>

      {/* Fixed Bottom Controls */}
      <Box className="controls-container">
        <div className="controls">
          <IconButton className="mute-btn">
            <FaMicrophone />
          </IconButton>
          <IconButton className="video-btn">
            <FaVideo />
          </IconButton>
          <IconButton className="record-btn">
            <FaStopCircle />
          </IconButton>
          <IconButton className="options-btn">
            <FaEllipsisH />
          </IconButton>
          <IconButton className="disconnect-btn">
            <FaPhoneAlt />
          </IconButton>
        </div>
      </Box>
    </Box>
    </GridContainer>
  );
};

export default Meet;