import GridContainer from 'components/molecules/GridContainer';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';
import './index.scss';
import { Avatar, MenuItem, Select, Checkbox, FormControlLabel } from '@mui/material';
import { AttendenceData, MODAL_STYTLE } from 'utils/constants';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useNavigate } from 'react-router-dom';
import userSingle from '../../assets/images/userSingle.png'
import Image from '../../assets/image'
import { colors } from 'assets/styles/colors';
import PrimaryButton from 'components/atoms/CustomButtons';
import CommonDeleteModal from 'components/atoms/CommonDeleteModal';

const Attendence: React.FC = () => {


    const activeClassroom = loadActiveClassroomLocalStorage()
    const navigate = useNavigate();
    const userObject = loadUserFromLocalStorage();
    const user = userObject?.userDetails
    const { classroomData } = useSelector((state: any) => state.classRoom) || {};
    const subjects = classroomData?.subjectList
    const timetable = activeClassroom?.timeTable
    const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    const today = new Date().getDay();
    const todayDay = days[today];
    const todayData = timetable.data?.find((item: any) => item.day === todayDay);
    const [subject, setSubject] = useState<any>(null);
    const [session, setSession] = useState<any>(null);
    const [students, setStudents] = useState([]);
    const [presentIds, setPresentIds] = useState<any>([]);
    const [absentIds, setAbsentIds] = useState<any>([]);
    const [showAllPresent, setShowAllPresent] = useState(false);
    const [showAllAbsent, setShowAllAbsent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setVisible] = useState(false);
    const [isErrorVisible, setError] = useState(false);

    const handleSubjectChange = (event: any) => {
        const selectedId = event.target.value;
        const selected = subjects?.find((subject: any) => subject?._id === selectedId);
        if (selected) {
            setSubject(selected);
        }
    };

    useEffect(() => {
        if (activeClassroom?._id) {
            getUserList(activeClassroom?._id);
        }
    }, [activeClassroom?._id]);

    const getUserList = async (id: string) => {
        const res = await fetchData(restAPIs.getUserList(id), navigate)
        if (res.statusCode === 200 && res.data) {
            setStudents(res.data?.classRoomUsers);
        }
    }

    const handleSessionChange = (event: any) => {
        const selectedSessionId = event.target.value;
        const selectedSession = todayData?.subjects?.find((subject: any) => subject?._id === selectedSessionId);
        if (selectedSession) {
            setSession(selectedSession);
        }
    };
    const onMarkPresent = (id: string) => {
        setShowAllAbsent(false);
        setShowAllPresent(false);
        if (!presentIds.includes(id)) {
            setPresentIds([...presentIds, id]);
        }
        if (presentIds.includes(id)) {
            setPresentIds(presentIds.filter((presentId: string) => presentId !== id));
        }
        if (absentIds.includes(id)) {
            setAbsentIds(absentIds.filter((absentId: string) => absentId !== id));
        }
    };

    const onMarkAbsent = (id: string) => {
        setShowAllAbsent(false);
        setShowAllPresent(false);
        if (!absentIds.includes(id)) {
            setAbsentIds([...absentIds, id]);
        }
        if (presentIds.includes(id)) {
            setPresentIds(presentIds.filter((presentId: string) => presentId !== id));
        }
        if (absentIds.includes(id)) {
            setAbsentIds(absentIds.filter((absentId: string) => absentId !== id));
        }
    };

    const onMarkAllPresent = () => {
        setShowAllPresent(!showAllPresent);
        setShowAllAbsent(false);
        if (!showAllPresent) {
            const ids = students.map((student: any) => student._id);
            setPresentIds(ids);
        } else {
            setPresentIds([]);
        }
        setAbsentIds([]);
    };

    const onMarkAllAbsent = () => {
        setShowAllAbsent(!showAllAbsent);
        setShowAllPresent(false);
        if (!showAllAbsent) {
            const ids = students.map((student: any) => student._id);
            setAbsentIds(ids);
        } else {
            setAbsentIds([]);
        }
        setPresentIds([]);
    };

    const onMarkAttendence = async () => {
        setIsLoading(true);
        try {
            let params = {
                classRoomId: activeClassroom?._id,
                instructorId: user._id,
                subjectId: subject?._id,
                sessionId: session?._id,
                presentIds,
                absentIds,
            };
            const response = await fetchData(restAPIs.getAttendencePost(params), navigate)
            if (response.statusCode === 200) {
                setVisible(true)

            } else {
                setError(true)
                //onToast('Something went wrong. Please try again!!');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const isPresent = (id: string) => presentIds.includes(id);
    const isAbsent = (id: string) => absentIds.includes(id);
    const getDisabled = () => (subject?._id && session?._id);

    return (

        <GridContainer backButton pageTitle={'Mark Attendance'} leftGridPartition={3 / 4}>
            <div className='attendence_container'>
                <div className="header">
                    <Select
                        className='select'
                        value={session?._id || AttendenceData.Empty}
                        displayEmpty
                        onChange={handleSessionChange} >
                        <MenuItem disabled value={AttendenceData.Empty}>{AttendenceData.SelectSession}</MenuItem>
                        {todayData?.subjects?.map((subject: any, index: number) => (
                            <MenuItem key={subject?._id} value={subject?._id}>
                                {`Session ${index + 1}`}
                            </MenuItem>
                        ))}
                    </Select>
                    <Select
                        className='select'
                        value={subject?._id || AttendenceData.Empty}
                        displayEmpty
                        onChange={handleSubjectChange} >
                        <MenuItem disabled value={AttendenceData.Empty}>{AttendenceData.SelectSubject}</MenuItem>
                        {subjects?.map((subject: any) => (
                            <MenuItem key={subject?._id} value={subject?._id}>
                                {subject?.subjectName}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <div className="student_heading">
                    {AttendenceData.Students}

                    <div className='heading_checkbox_wrapper'>
                        <div className='checkbox_content'>
                            <FormControlLabel
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: '16px',
                                        fontWeight: '600',
                                        color: colors.secondary,
                                    },
                                }}
                                control={
                                    <Checkbox
                                        checked={showAllPresent}
                                        onChange={onMarkAllPresent}
                                        sx={{
                                            '&.Mui-checked': {
                                                color: '#4DCC52',
                                            },
                                        }}
                                    // checked={fals}
                                    // onChange={handleChange}
                                    // color="primary"
                                    />
                                }
                                label="P"
                            />
                        </div>
                        <div className='checkbox_content'>
                            <FormControlLabel
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: '16px',
                                        fontWeight: '600',
                                        color: colors.secondary,
                                    },
                                }}
                                control={
                                    <Checkbox
                                        checked={showAllAbsent}
                                        onChange={onMarkAllAbsent}
                                        sx={{
                                            '&.Mui-checked': {
                                                color: '#FF0000',
                                            },
                                        }}
                                    // checked={fals}
                                    // onChange={handleChange}
                                    // color="primary"
                                    />
                                }
                                label="A"
                            />
                        </div>
                    </div>
                </div>
                <div className="students_wrapper">
                    {students?.map((item: any) =>
                        item?.role !== "STUDENT" ?
                            null :
                            (
                                <div className='student'>
                                    <Avatar
                                        src={userSingle}
                                        sx={{ width: 34, height: 34, fontSize: '14px' }}
                                        aria-label="recipe"
                                    >
                                    </Avatar>
                                    <div className="student_text">
                                        {item.firstName + ' ' + item.lastName}
                                    </div>
                                    <div className='checkbox_wrapper'>
                                        <div className='checkbox_content'>
                                            <Checkbox
                                                checked={isPresent(item._id)}
                                                onChange={() => onMarkPresent(item?._id)}
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: '#4DCC52',
                                                    },
                                                }}
                                            />
                                        </div>
                                        <div className='checkbox_content_abscent'>
                                            <Checkbox
                                                onChange={() => onMarkAbsent(item?._id)}
                                                checked={isAbsent(item._id)}
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: '#FF0000',
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                </div>
                <div className='footer'>
                    <PrimaryButton
                        fullWidth={false}
                        isLoading={isLoading}
                        onClick={() => {
                            onMarkAttendence();
                        }}
                        disabled={!getDisabled() || isLoading}
                        color="primary"
                    >
                        <div style={{ minWidth: '60px' }}>Save</div>
                    </PrimaryButton>
                </div>
                <CommonDeleteModal
                    open={isVisible}
                    handleSubmit={() => {
                        setVisible(false)
                        navigate(-1)
                    }}
                    buttonLabel={'Got it!'}
                    description={`Attendance updated successfully`}
                    title={'Success'}
                    modalStyle={MODAL_STYTLE}
                    handleClose={() => setVisible(false)}
                />
                <CommonDeleteModal
                    open={isErrorVisible}
                    handleSubmit={() => setError(false)}
                    buttonLabel={'Ok'}
                    description={`Something went wrong. Please try again!!`}
                    title={'Something went wrong'}
                    modalStyle={MODAL_STYTLE}
                    handleClose={() => setError(false)}
                />
            </div>
        </GridContainer>
    );
};

export default Attendence;