
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
import { clearUserDetails, updateUserClassRoom, updateUserDetails } from '../redux/slices/setUser/userSlice'
import {
  clearClassroomData,
  clearSelectedClassMetadata,
  setSelectedClassMetadata,
  clearActiveClassroom,
  setUpdateTodaysEvent,
  setUpdateEvent,
  setUpdateDocument,
  setUpdateCarriculam,
  setUpdateSubject,
  setActiveClassRoom,
  setUpdateNote,
  setLoadingNotes,
  setLoadingDocument,
  setLoadingSubject,
  setLoadingEvent,
  setLoadingCarriculam,
  setPostAccess,
  setAcademicAccess,
  setUpdateUserType,
  setUpdateTimeTable
} from "../redux/slices/setClassroom/classRoomSlice";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../components/atoms/SnackbarProvider";
import { useMetadataContext } from "contextApi/MetadataContext";
import useSWR from 'swr';
import { loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from "./commonUtils";
import { useState, useEffect } from "react";

export const useJoinRoom = () => {
  const dispatch = useDispatch();
  const getUpdateUser = useUpdateUser()
  const navigate = useNavigate();
  const swichClass = useSwichClass()
  const user = loadUserFromLocalStorage();

  const joinRoom = async (id: string, isPublic: boolean) => {
    const res = await fetchData(restAPIs.getClassRoom(id, { userId: user?._id }), navigate);
    if (res.status === 200 && res.data) {
      let temp_data = res.data;
      if (isPublic) {
        temp_data.membershipStatus = 'ACTIVE';
      }
      dispatch(updateUserClassRoom(temp_data))
      if (isPublic)
        swichClass(id, isPublic)
      else {
        getUpdateUser()
      }
    }
  };

  return joinRoom;
};

export const useClassMetadata = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getClassMetadata = async (id: string) => {
    dispatch(clearSelectedClassMetadata())
    const res = await fetchData(restAPIs.getMetaData(id), navigate)
    if (res.statusCode === 200 && res.data) {
      dispatch(setSelectedClassMetadata(res.data))
    }
    else {
      console.error('error', res)
    }
  }

  return getClassMetadata;
};

export const useSwichClass = () => {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { SelectedClassRoom } = useSelector((state: any) => state.classRoom) || {};
  const activeClassroom = loadActiveClassroomLocalStorage()
  const swichClass = async (id: string, skipMembershipCheck?: boolean) => {
    if (SelectedClassRoom?.membershipStatus == 'ACTIVE' || skipMembershipCheck) {
      if (activeClassroom?._id == id) {
        showSnackbar('Already in this class room', 'success')
      }
      else {
        dispatch(clearClassroomData())
        localStorage.removeItem('classroomData')
        navigate(`/classroomfetch/${id}/switch`)
      }
    }
  }

  return swichClass;
};

export const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const logOut = async () => {
    dispatch(clearUserDetails())
    dispatch(clearClassroomData())
    dispatch(clearActiveClassroom())
    navigate('/login')
  }

  return logOut;
};

export const performLogout = (dispatch: Function, navigate: Function) => {
  return async () => {
    dispatch(clearUserDetails());
    dispatch(clearClassroomData());
    dispatch(clearActiveClassroom());
    navigate('/login');
  };
};

export const useFetchClassRoomItems = () => {

  const navigate = useNavigate();
  const user = loadUserFromLocalStorage();
  const date = new Date();
  date.setDate(date.getDate() + 1);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  let dayStart = new Date(new Date().setHours(0, 0, 0, 0)).toISOString();
  let dayEnd = new Date(new Date().setHours(23, 59, 59, 999)).toISOString();

  const dispatch = useDispatch();
  const activeClassroom = loadActiveClassroomLocalStorage()
  const { setMetadata, setIsMetaDataLoading, setNotificationCount } = useMetadataContext();

  const fetchTodaysEvents = async (classRoomId: string | undefined) => {
    let params = {
      startTime: dayStart,
      endTime: dayEnd,
      page: 1,
      limit: 10,
      classRoomId: classRoomId,
    };
    dispatch(setLoadingEvent())
    const res = await fetchData(restAPIs.getEvents(params), navigate)
    if (res?.statusCode === 200 && res?.data)
      dispatch(setUpdateTodaysEvent(res?.data))
  }

  const fetchEvents = async (classRoomId: string | undefined) => {
    let params = {
      page: 1,
      limit: 10,
      classRoomId: classRoomId,
    };
    dispatch(setLoadingEvent())
    const res = await fetchData(restAPIs.getEvents(params), navigate)
    if (res?.statusCode === 200 && res?.data)
      dispatch(setUpdateEvent(res?.data))
  }

  const fetchDocuments = async (classRoomId?: string | undefined, offset?: number, type?: string) => {
    let params = {
      classRoomId: classRoomId ?? activeClassroom?._id,
      limit: 10,
      page: offset ?? 1,
    };
    dispatch(setLoadingDocument())
    const res = await fetchData(restAPIs.getDocuments(params), navigate)
    if (res?.statusCode === 200 && res?.data)
      dispatch(setUpdateDocument({ ...res?.data, offset: offset }))
  }

  const fetchCurriculum = async (subjectId: string) => {
    dispatch(setLoadingCarriculam(true))
    const res = await fetchData(restAPIs.getCurriculum(subjectId), navigate)
    if (res?.statusCode === 200 && res?.data)
      console.log('')
    dispatch(setUpdateCarriculam(res?.data?.curriculum || []))
    dispatch(setLoadingCarriculam(false))
  }

  const fetchSubject = async (classRoomId?: string, page?: number) => {
    dispatch(setLoadingSubject())
    const res = await fetchData(restAPIs.getSubjects(classRoomId), navigate)
    if (res?.statusCode === 200 && res?.data)
      dispatch(setUpdateSubject({ ...{ subjects: res?.data }, offset: page }))
  }

  const fetchClassroom = async (classRoomId?: string) => {
    if (!classRoomId) return
    const res = await fetchData(restAPIs.getActiveClassDetails(classRoomId), navigate)
    if (res?.statusCode === 200 && res?.data) {
      const temp_activeClassroom = res?.data
      dispatch(setActiveClassRoom(res?.data))
      const {
        memberPermissions = {},
        owners = [],
      } = temp_activeClassroom || {};
      const temp_post_acces = memberPermissions?.board ?? false;
      const temp_academic_acces = memberPermissions?.academic ?? false;
      const temp_havePostAccesss =
        owners?.some((owner: any) => owner.userId === user?._id) || temp_post_acces;
      const temp_haveAcademicAccesss =
        owners?.some((owner: any) => owner.userId === user?._id) || temp_academic_acces;
      const temp_userType = owners?.some((owner: any) => owner.userId === user?._id);
      dispatch(setPostAccess(temp_havePostAccesss))
      dispatch(setAcademicAccess(temp_haveAcademicAccesss))
      dispatch(setUpdateUserType(temp_userType))
    }
  }

  const fetchNotes = async (classRoomId?: string, page?: number) => {
    let params = {
      classRoomId: classRoomId ?? activeClassroom?._id,
      limit: 10,
      page: page ?? 1,
    };
    dispatch(setLoadingNotes())
    const res = await fetchData(restAPIs.getNotes(params), navigate)
    if (res?.statusCode === 200 && res?.data)
      dispatch(setUpdateNote({ ...res?.data, offset: page }))
  }

  const fetchTimeTable = async (classRoomId?: string, page?: number) => {
    dispatch(setLoadingNotes())
    const res = await fetchData(restAPIs.getTimeTable(classRoomId ?? activeClassroom?._id), navigate)
    if (res?.statusCode === 200 && res?.data)
      dispatch(setUpdateTimeTable({ ...res?.data, offset: page }))
  }

  const fetchMetaData = async () => {
    setIsMetaDataLoading(true)
    const res = await fetchData(restAPIs.getMetaData(activeClassroom?._id), navigate)
    setIsMetaDataLoading(false)
    if (res.statusCode === 200 && res.data) {
      setMetadata(res.data);
    }
  }

  const fetchNotifications = async () => {
    setIsMetaDataLoading(true)

    let params = {
      page: 1,
      limit: 10
    };
    const response = await fetchData(restAPIs.getNotificationList(params), navigate);
    setIsMetaDataLoading(false)
    if (response.statusCode === 200 && response.data) {
      setNotificationCount(response?.data?.unreadNotifications ?? 0);
    }
  }

  return { fetchTodaysEvents, fetchTimeTable, fetchEvents, fetchDocuments, fetchCurriculum, fetchSubject, fetchClassroom, fetchNotes, fetchMetaData, fetchNotifications };
};

export const useUpdateUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setIsUpdateUserLoading } = useMetadataContext();

  const getUpdateUser = async () => {
    setIsUpdateUserLoading(true)
    const res = await fetchData(restAPIs.getUserUpdate('get'), navigate)
    // const classroomList = await fetchData(restAPIs.getClassList(), navigate)
    setIsUpdateUserLoading(false)
    if (res.statusCode === 200 && res.data) {
       dispatch(updateUserDetails(res.data))
      // if (classroomList.statusCode === 200) {
      //   dispatch(updateUserClassRoom(classroomList.data?.classrooms))
      // }
    }
    else {
      console.error('error', res)
    }
  }

  return getUpdateUser;
};

export function useCustomSWR(request: any, deps: any[] = []) {
  const navigate = useNavigate();

  const { data, error, mutate } = useSWR(
    deps,
    () => fetchData(request, navigate),
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate
  };
}


const useOnlineStatus = () => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  const updateOnlineStatus = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  return isOnline;
};

export default useOnlineStatus;

export const useCheckRole = () => {
  const userObject = loadUserFromLocalStorage();
  const user = userObject?.userDetails;

  const isInstructor = (): boolean => {
    return user?.role === 'INSTRUCTOR';
  };

  const isStudent = (): boolean => {
    return user?.role === 'STUDENT';
  };

  return { isInstructor, isStudent };
};