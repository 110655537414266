import React, { useEffect, useState } from 'react';
import ModalView from '../ModalView';
import { IoIosClose } from "react-icons/io";
import './index.scss'
import { useDispatch, useSelector } from 'react-redux';
import EventDatePicker from '../EventDatePicker';
import PrimaryButton from '../CustomButtons';
import { CreateNoteModalData, EVENT_TITLE } from 'utils/constants';
import { MenuItem, Select, Typography } from '@mui/material';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import moment from 'moment-timezone';
import { useFetchClassRoomItems } from 'utils/customHooks';
import { useNavigate } from 'react-router-dom';
import { DateValidationError, TimeValidationError } from '@mui/x-date-pickers/models';
import { useSnackbar } from '../SnackbarProvider';
import { useMetadataContext } from 'contextApi/MetadataContext';
import { combineDateTimeToUTC, loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';
import { logEventWeb } from 'utils/firebase';

interface Props {
    handleClose?: () => void;
    open: any;
    modalStyle?: any;
    handleOpen?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    createType?: string;
    editItem?: any
    eventDetailModalClose?: () => void;
}
interface SubjectChange {
    target: {
        value: string;
    };
}

interface FormErrorState {
    name: string;
    description: string;
}

const initialerrors = {
    name: '',
    description: ''
}
interface EventForm {
    startDate: moment.Moment | any;
    endDate: moment.Moment | any;
    startTime: moment.Moment | any;
    endTime: moment.Moment | any;
}

const CreateExamModal: React.FC<Props> = ({ open, handleClose, modalStyle, createType, editItem, eventDetailModalClose }) => {

    const initialEventForm = {
        startDate: dayjs(),
        endDate: dayjs().add(1, 'day'),
        startTime: dayjs(),
        endTime: dayjs().add(1, 'day')
    }

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { setToogleDrawer } = useMetadataContext();
    const { showSnackbar } = useSnackbar();
    const { fetchEvents, fetchTodaysEvents, fetchMetaData } = useFetchClassRoomItems()

    const { classroomData } = useSelector((state: any) => state.classRoom) || {};

    const subjects = classroomData?.subjectList
    const activeClassroom = loadActiveClassroomLocalStorage()
    const userObject = loadUserFromLocalStorage();
    const user = userObject?.userDetails
    const [name, setName] = useState('');
    const [noOfQns, setNoOfQns] = useState(1);
    const [duration, setDuration] = useState<any>(1);
    const [mark, setMark] = useState(1);
    // const [questions, setQuestions] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState<any>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [description, setDescription] = useState('');
    const [errors, setErrors] = useState<FormErrorState>(initialerrors);
    const [notify, setNotify] = useState(true);
    const [dateError, setDateError] = React.useState<DateValidationError | null>(null);
    const [timeError, setTimeError] = React.useState<TimeValidationError | null>(null);
    const [eventForm, setEventForm] = useState<EventForm>(initialEventForm);
    const [questions, setQuestions] = useState<{ question: string; duration: string; mark: string }[]>(
        Array(noOfQns).fill({ question: "", duration: "", mark: "" })
    );
    const [qnErrors, setQnErrors] = useState<{ [key: string]: string }>({});

    const [durationErrors, setDurationErrors] = useState<{ [key: string]: string }>({});
    const [noOfQnErrors, setNoOfQnErrors] = useState<{ [key: string]: string }>({});


    const handleSubjectChange = (event: any) => {
        const selectedId = event.target.value;
        const selected = subjects?.find((subject: any) => subject?._id === selectedId);
        if (selected) {
            setSelectedSubject(selected);
        }
        else if (event.target.value === CreateNoteModalData.None) {
            setSelectedSubject(CreateNoteModalData.Empty);
        }
    };

    useEffect(() => {
        if (open) {
            setEventForm({
                startDate: dayjs(),
                endDate: dayjs().add(1, 'day'),
                startTime: dayjs(),
                endTime: dayjs().add(1, 'day'),
            });
        }
    }, [open]);

    useEffect(() => {
        if (editItem) {
            dayjs.extend(utc);
            setName(editItem.name)
            const temp = {
                ...initialEventForm,
                startDate: dayjs(editItem?.startTime),
                startTime: dayjs(editItem?.startTime),
                endDate: dayjs(editItem?.endTime),
                endTime: dayjs(editItem?.endTime)

            }
            setEventForm(temp)
            setDescription(editItem.description)
        }
    }, [editItem]);

    const handleDescriptionChange = (event: SubjectChange) => {
        setDescription(event.target.value)
    };

    const onSuccess = () => {
        fetchTodaysEvents(activeClassroom?._id)
        fetchEvents(activeClassroom?._id)
        fetchMetaData()
        onClose?.()
        navigate('/events')
        showSnackbar(`${createType} ${editItem ? 'updated' : 'created'}`, 'success')
        if (editItem) {
            eventDetailModalClose?.()
        }
        setToogleDrawer(false)
    }

    const onSubmit = async () => {
        // if (name?.trim()?.length > 0 && timeError == null && dateError == null) {
        //     setIsLoading(true)
        //     let params = {
        //         ...(!editItem && { classRoomId: activeClassroom?._id }),
        //         name: name.trim(),
        //         startTime: combineDateTimeToUTC(new Date(eventForm?.startDate), new Date(eventForm?.startTime)),
        //         endTime: combineDateTimeToUTC(new Date(eventForm?.endDate), new Date(eventForm?.endTime)),
        //         description:
        //             description?.length > 0 ? description.trim() : '',
        //     };
        //     const res = editItem ? await fetchData(restAPIs.getupdateEvents(editItem._id, params), navigate) : await fetchData(restAPIs.getAddEvents(params), navigate)
        //     setIsLoading(false)
        //     if (res.statusCode === 200 && res.data) {
        //         onSuccess()
        //         if (!editItem) {
        //             logEventWeb('user_interaction', {
        //                 interaction_type: 'add_event',
        //                 id: String(res.data._id),
        //                 org_id: String(user?.organizationId),
        //                 class_id: String(activeClassroom?._id),
        //                 custom_user_id: String(user?._id),
        //             });
        //         }
        //     }
        //     else {
        //         showSnackbar('something went wrong !', 'error')
        //     }
        // }
        // else {
        //     if (name?.trim()?.length == 0) {
        //         let newErrors = { ...initialerrors };
        //         newErrors.name = '* Required';
        //         setErrors(newErrors)
        //     }
        // }
    }

    const onClose = () => {
        setEventForm(initialEventForm)
        setName('')
        setErrors(initialerrors)
        setDescription('')
        handleClose?.()
    }

    // const handleNoOfQnsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = event.target.value.trim();
    //     const numberValue = Number(value);

    //     // Check if value is a number and within the range 1-20
    //     if (/^\d+$/.test(value) && numberValue >= 1 && numberValue <= 20) {
    //         setNoOfQns(numberValue); // Ensure state is updated as a number
    //         setErrors((prev) => ({ ...prev, name: "" })); // Clear error
    //     } else {
    //         setErrors((prev) => ({ ...prev, name: "Please enter a number between 1 and 20" }));
    //     }
    // };

    const handleNoOfQnsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();

        // Allow empty input for user to edit
        if (value === "") {
            setNoOfQns(value as unknown as number); // Temporarily allow an empty string
            setNoOfQnErrors((prev) => ({ ...prev, name: "Please enter a number between 1 and 20" }));
            return;
        }

        const numberValue = Number(value);

        // Check if value is a valid number within range
        if (/^\d+$/.test(value) && numberValue >= 1 && numberValue <= 20) {
            setNoOfQns(numberValue);
            setNoOfQnErrors((prev) => ({ ...prev, name: "" })); // Clear error
        } else {
            setNoOfQnErrors((prev) => ({ ...prev, name: "Please enter a number between 1 and 20" }));
        }
    };

    const handleDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();

        // Allow empty input for user to edit
        if (value === "") {
            setDuration(value as unknown as number); // Temporarily allow an empty string
            setDurationErrors((prev) => ({ ...prev, name: "Please enter a number between 1 and 180" }));
            return;
        }

        const numberValue = Number(value);

        // Check if value is a valid number within range
        if (/^\d+$/.test(value) && numberValue >= 1 && numberValue <= 180) {
            setDuration(numberValue);
            setDurationErrors((prev) => ({ ...prev, name: "" })); // Clear error
        } else {
            setDurationErrors((prev) => ({ ...prev, name: "Please enter a number between 1 and 180" }));
        }
    };

//     const handleDurationChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
//         const value = event.target.value.trim();
// console.log('entered',value)
//         // Allow empty input for editing
//         if (value === "") {
//             setDuration("");
//             setDurationErrors("");
//             return;
//         }

//         const numberValue = Number(value);

//         if (/^\d+$/.test(value) && numberValue >= 1 && numberValue <= 60) {
//             setDuration(numberValue);
//             setDurationErrors("");
//         } else {
//             setDurationErrors("Enter a number between 1 and 60");
//         }
//     };

    const handleQnChange = (index: number) => (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value.trim();

        setQuestions((prev) => {
            const newQuestions = [...prev];
            newQuestions[index] = { ...newQuestions[index], question: value };
            return newQuestions;
        });

        setQnErrors((prev) => ({
            ...prev,
            [`question_${index}`]: value ? "" : "Question cannot be empty",
        }));
    };

    const handleMarkChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();

        if (value === "") {
            setQuestions((prev) => {
                const newQuestions = [...prev];
                newQuestions[index] = { ...newQuestions[index], mark: "" };
                return newQuestions;
            });
            setQnErrors((prev) => ({ ...prev, [`mark_${index}`]: "" }));
            return;
        }

        const numberValue = Number(value);

        if (/^\d+$/.test(value) && numberValue >= 1 && numberValue <= 100) {
            setQuestions((prev) => {
                const newQuestions = [...prev];
                newQuestions[index] = { ...newQuestions[index], mark: value };
                return newQuestions;
            });
            setQnErrors((prev) => ({ ...prev, [`mark_${index}`]: "" }));
        } else {
            setQnErrors((prev) => ({ ...prev, [`mark_${index}`]: "Enter a number between 1 and 100" }));
        }
    };


    const RenderModal = () => (
        <div className="create-events-modal">
            <div className='__header'>
                <h4>{editItem ? 'Edit' : 'Add'} {createType}</h4>
                <button onClick={onClose}><IoIosClose /></button>
            </div>
            <div className="file-upload-container">
                <div className="input-group">
                    <label>Exam Title</label>
                    <input value={name} type="text" onChange={handleSubjectChange} placeholder={`${createType} Title`} />
                    <Typography sx={{ color: 'red' }} variant='subtitle2'>{errors?.name}</Typography>
                </div>
                <div className="input-group">
                    <label>Select Subject</label>
                    {subjects?.length > 0 &&
                        <div className="__session__note">
                            <Select
                                className='select'
                                value={selectedSubject?._id ?? CreateNoteModalData.None}
                                onChange={handleSubjectChange} >
                                <MenuItem value={CreateNoteModalData.None}>{CreateNoteModalData.Nones}</MenuItem>
                                {subjects?.map((subject: any) => (
                                    <MenuItem key={subject?._id} value={subject?._id}>
                                        {subject?.subjectName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    }
                </div>
                <EventDatePicker
                    eventForm={eventForm}
                    setEventForm={setEventForm}
                    dateError={dateError}
                    setDateError={setDateError}
                    timeError={timeError}
                    setTimeError={setTimeError} />
                <div className="input-group">
                    <label>Number of Questions</label>
                    <input value={noOfQns} type="number" onChange={handleNoOfQnsChange} placeholder={`Number of Questions`} />
                    <Typography sx={{ color: 'red' }} variant='subtitle2'>{noOfQnErrors?.name}</Typography>
                </div>

                <div className="input-group">
                    <label>{`Duration for Exam (in minutes)`}</label>
                    <input value={duration} type="number" onChange={handleDurationChange} placeholder={`Duration for Question (in minutes)`} />
                    <Typography sx={{ color: 'red' }} variant='subtitle2'>{durationErrors?.name}</Typography>
                </div>
                {Array.from({ length: noOfQns }, (_, index) => (
                    <div key={index}>
                        <div className="input-group">
                            <label>{`Question ${index + 1}`}</label>
                            <textarea value={questions[index]?.question || ""} onChange={handleQnChange(index)} placeholder={`Question ${index + 1}`} />
                            <Typography sx={{ color: 'red' }} variant='subtitle2'>{qnErrors[`question_${index}`]}</Typography>
                        </div>
                        <div className="input-group">
                            <label>{`Mark for Question ${index + 1}`}</label>
                            <input value={questions[index]?.mark || ""} type="number" onChange={handleMarkChange(index)} placeholder={`Mark for Question ${index + 1}`} />
                            <Typography sx={{ color: 'red' }} variant='subtitle2'>{qnErrors[`mark_${index}`]}</Typography>
                        </div>
                    </div>
                ))}
            </div>
            <div className='modal-footer-button'>
                <PrimaryButton
                    onClick={onSubmit}
                    fullWidth={false}
                    isLoading={isLoading}>
                    {editItem ? 'Update' : 'Add'} {createType}
                </PrimaryButton>
            </div>
        </div>
    )

    return (
        <ModalView open={open} handleClose={onClose} modalStyle={modalStyle}>
            {RenderModal()}
        </ModalView>
    )
};

export default CreateExamModal;
