// Core
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components
import GridContainer from 'components/molecules/GridContainer';
import SubjectsFiles from 'components/atoms/SubjectsFiles';

// Third-Party Libraries
import { CircularProgress, Typography } from '@mui/material';

// Styles and Constants
import './index.scss'
import { colors } from 'assets/styles/colors';

// Utilities
import { useCheckRole } from 'utils/customHooks';
import {
    dummyExamData,
    ExamsData,
    SubjectsData
} from 'utils/constants';

const Exams: React.FC = () => {

    const { classroomData } = useSelector((state: any) => state.classRoom) || {};
    const { isInstructor } = useCheckRole()
    const [subjectList, setsSubjects] = useState(classroomData?.subjectList);
    const [selected, setSelected] = useState<number>(0)



    return (
        <GridContainer
            pageTitle={ExamsData.Exams}
            leftGridPartition={1 / 2.5}>

            <div style={{ marginBottom: '20vh' }}>
                {dummyExamData?.map((item: any, index: number) => {
                    return (
                        <div onClick={() => setSelected(index)} className={`notes-container ${selected == index ? 'selected' : ''}`}>

                            <div className="title-note-container">
                                <Typography fontWeight={500} fontSize={'.8rem'} variant='h4' key={index}>
                                    {item.name}
                                </Typography>
                            </div>
                                <div style={{ gap: '5px' }} className='notes-sub-container'>
                                {item?.subject?.length > 0 &&
                                    <Typography fontSize={'10px'} className='subjects-count-name subject'>
                                        {item.subject}
                                    </Typography>}
                                    <Typography fontSize={'10px'} className='subjects-count-name'>
                                       No of questions: {item.noOfQns}
                                    </Typography>
                                    <Typography fontSize={'10px'} className='subjects-count-name'>
                                       Duration: {item.duration} mts
                                    </Typography>
                                </div>
                            <div className={`${selected == index ? 'dropdown-menu' : 'hide-dropdown-menu'}`} >
                                {/* <SubjectsFiles subjects={subjectList[selected]}
                                    type={SubjectsData.Subjects}
                                    getSections={getSections} /> */}
                            </div>
                            <div className='line-separator' />
                        </div>
                    );
                })}
            </div>
        </GridContainer>
    )
};

export default Exams;
