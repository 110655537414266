export const NOTE_TITLE = 150;
export const DOCUMENT_TITLE = 150;
export const EVENT_TITLE = 150;
export const SUBJECT_TITLE = 150;
export const SESSION_TITLE = 150;
export const CLASSNAME = 25;
export const CLASSID = 10;
export const FIRST_NAME_LENGTH = 32
export const LAST_NAME_LENGTH = 32
export const CHAT_NAME_LENGTH = 32

export const GROUP_NAME_LENGTH = 64;
export const CLASSROOM_BIO_LENGTH = 250;
export const LINK_LENGTH = 2000;

export const fileType = ['pdf', 'video', 'doc', 'img', 'file']

export const NOTIFICATION_MODULES = {
  document: 'DOCUMENT',
  note: 'NOTE',
  event: 'EVENT',
  timetable: 'TIMETABLE',
  curriculum: 'CURRICULUM',
  subject: 'SUBJECT',
  userRemoval: 'USER_REMOVAL',
  userApproval: 'USER_APPROVAL',
  userAdmin: 'USER_ADMIN',
  user: 'USER',
  post: 'POST',
};

export const CREATE_CLASS_FOOTER_TEXT = 'This name will be displayed to your classmates on joining. you can change the name in future.';
export const CREATE_CLASS_ID_FOOTER_TEXT = 'This ID will be displayed to your classmates on joining. You cannot change the ID in the future.';
export const TEMPLATE_INFO_TEXT = 'Now create classroom from University / Board template and get recent updates.';
export const SET_PRIVACY_PUBLIC_DESC = 'Anyone can join this classroom';
export const SET_PRIVACY_PRIVATE_DESC = 'Only people given access can join';
export const SET_PRIVACY_TOOGLE_INFO_TITLE = 'Get latest news and updates';
export const SET_PRIVACY_TOOGLE_INFO = 'Keep updated with latest news and current affairs from your interest of study';
export const SET_PRIVACY_IMAGE_PICKER = [
  {
    title: 'Add Classroom Logo',
    data: { title: 'Upload a photo', desc: 'You can either upload photo or take it now' }
  },
  {
    title: 'Add Classroom Cover',
    data: { title: 'Upload a photo', desc: 'You can either upload photo or take it now' }
  }
];
export const SET_PRIVACY_CLASSROOM_DESC = 'Write about your classroom…';

export const CLASSROOM_STATUS = {
  PRIVATE: 'Private',
  PUBLIC: 'Public',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  PENDING: 'Pending',
};

export const restrictModalData = { 
  title: 'Access Restricted', 
  desc: 'Sorry, only instructors are allowed to add notes,documents,events,subjects & timetable in this classroom.', 
  button: "Ok. got it" 
}

export const preferenceData = [
  {
      title: 'Join Classroom',
      desc: 'Search with the classroom name or ID, find your classroom',
      route: '/findclassroom'
  },
]

export const PATH_TO_CHECK_SIDE_MENU = ['classroomfetch','class-name','class-id','template','set-privacy','home','findclassroom']
export const NO_POSTS = 'Share your first post with your classmates';  
export const MODAL_STYTLE={ padding: '0px' };
export const ACCOUNT_SETTINGS = ['Change Password', 'Logout']

export const LEAVE_ROOM_MODAL ={
  title:'Leave',
  desc:'Are you sure you want to leave this classroom?',
  button:"Yes, I'am Sure!"
}

export const classroomSettings = ['Classmates', 'Subjects', 'My Activities'];
export const accountSettings = ['Account', 'Report a Problem', 'Help & Support']

export const AccountSettingsdata = [
  { title: 'Classroom Settings', settings: classroomSettings },
  { title: 'Account Settings', settings: accountSettings }
]

export const AccountSettingsArray=['My Activities', 'Account', 'Help & Support', 'Subjects', 'Classmates']

export const initialTimes = ['09:30 am', '10:30 am', '11:30 am', '12:30 pm', '01:30 pm', '02:30 pm', '03:30 pm', '04:30 pm', '05:30 pm', '06:30 pm', '07:30 pm', '08:30 pm'];
export const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const abbreviatedDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

// enums
// Common

export enum CursorType {
  Pointer= 'pointer',
  Default= '',
  Clickaway='clickaway',
  NotAllowed='not-allowed'
}

export enum KeyEventType {
  Enter = 'Enter'
}

export enum CommonTypes {
  NOTE = 'NOTE',
  DOCUMENT='DOCUMENT',
  EVENT='EVENT',
  POLL='POLL',
  POST='POST'
}

export enum CreateTypes {
  Note = 'Note',
  Subject='Subject'
}

export enum LoginFormKeys {
  SignInForm = 'signInForm',
  PhoneNumberLogin='phoneNumberLogin',
  CreateUser='createUser',
  SignUpForm='signUpForm',
  OtpScreen='otpScreen',
  ForgetPassword='forgetPassword',
  ResetPassword='resetPassword'
}

export enum InputLabels{
  FirstName='First Name',
  LastName='Last Name',
  Email='Email',
  NewPassword='New Password',
  ConfirmPassword='Confirm Password',
  Password='Password',
  SearchUniversityBoard='Search University/Board'
}

export enum InputVariants{
  Outlined='outlined'
}

export enum InputNames{
  FirstName='firstName',
  LastName='lastName',
  Email='email',
  Password='password',
  ConfirmPassword='confirmPassword',
}

export enum InputMargins{
  Normal='normal'
}

export enum InputTypes{
  Text='text',
  Password='password'
}

export enum VisibilityTypes{
  Visible='visible',
  Hidden='hidden'
}

export enum DeviceType{
  Mobile='Mobile',
  Desktop='Desktop',
  Tablet='Tablet'
}

export enum UserObjectKeys {
  Active = 'ACTIVE',
}

// Classroom settings
export enum ClassroomDataKeys {
  Name = 'Name',
  Id = 'ID',
  About = 'About',
  Placeholder='Write about your classroom . . .' 
}

export enum ClassroomData2Keys {
  JoiningLink = 'Joining link',
  QrCode = 'QR Code',
  value = 'Share it with your friends to join the classroom.',
}

export enum ClassroomPermissionData {
  WriteOnBoard = 'Write on board',
  Academic = 'Academic',
  Everyone = 'Everyone',
  OnlyAdmins='Only Admins' 
}

export enum ClassroomSettingsData {
  ChangeLogo = 'Change classroom logo',
  ChangeCover = 'Change classroom cover',
  Privacy = 'Privacy',
  Public = 'Public',
  Private = 'Private',
  NewsUpdates = 'Get latest news and updates',
  NewsDescription = 'Keep updated with latest news and current affairs from your interest of study.',
  LeaveClassroom = 'Leave', 
  LeaveClassroomDescription = 'You will no longer have access to this classroom.'
}

export enum PrivacyOptions {
  Everyone = 'Everyone',
  AdminApproval = 'Only people with admin approval',
  OnlyAdmins = 'Only Admins',
}

export enum ClassroomSettingsIcons {
  Earth = 'fi fi-sr-earth-americas',
  Lock = 'fi fi-sr-lock',
  CheckCircle='fi fi-ss-check-circle icon',
  AngleRight='fi fi-rs-angle-right',
  Camera='fi fi-rr-camera',
  Pencil='fi fi-rr-pencil',
  Upload='fi fi-rr-upload'
}

export enum ClassroomSettingsDataType {
  PermissionData='permissionData',
  PrivacyData='privacyData',
  EditData='editData',      
}

export enum ClassroomPermissionModal {
  JoinClassroom = 'Who can join to your classroom?',
  WriteOnBoard = 'Who can write on board',
  CreateAcademicActivities = 'Select who can create academic activities?'
}

export enum ClassroomPermissionModalDescriptions {
  WriteOnBoard = 'Pick who can create posts on board',
  CreateAcademicActivities = 'Pick who can create notes, documents, events...etc'
}

export enum ClassroomImageType{
  Logo='logo',
  Cover='cover'
} 

export enum SnackBarMessages{
  ClassroomUpdated='Classroom updated',
  SomethingWentWrong='Something went wrong !',
  ClassroomLeft='Classroom left',
  DocumentDeletedSuccessfully='Document deleted successfully',
  PostDeletedSuccessfully='Post deleted successfully',
  ApologiesUnableProcessReport='Apologies, we were unable to process your report. Please try again later.',
  PleaseChooseOption='Please choose an option',
  PasswordChangedSuccessfully='Password Changed successfully',
  NoteDeletedSuccessfully='Note deleted successfully',
  LogoSomethingWrong='Something Went wrong on uploading logo.',
  CoverSomethingWrong='Something Went wrong on uploading cover image.',
  SubjectDeletedSuccessfully='Subject deleted successfully',
  TimetableUpdatedSuccessfully='Timetable updated successfully'
} 

export enum StatusType{
  success='success',
  error='error',
  info='info',
  warning='warning'
}

export enum ClassroomSettingsConstData{
  Name='Name',
  Update='Update',
  academic='academic',
  Post='post',
  LinkCopied='Link copied!',
  ReadLess='Read Less',
  ReadMore='Read More',
  NoDescription='No description',
  University='University/Board',
  NoUniversity='No University/Board',
  ClassmatesPermission='Classmates permission',
  ClassroomSettings='Classroom settings'
}

// Curriculum

export enum CurriculamData{
  AddSession='Add Session',
  Session='Session',
  Sessions='Sessions',
  Curriculum='Curriculum',
  Subject='Subject',
}

// DocumentDetails

export enum DocumentDetailsData{
  Document='Document',
  Subject='Subject:',
  CreatedBy='Created by:',
  File=' File',
  Files=' Files',
  DocumentsUnavailable='Documents unavailable'
}

export enum DocumentDetailsDeleteModal{
  YesDelete='Yes, Delete',
  Desc='This action cannot be undone. Are you sure you want to proceed?',
  Title='Delete Document',
  DeleteTitle='Delete File',
  DeletePost='Delete Post',
  DeleteNote='Delete Note'
}

// Documents

export enum DocumentsData{
  Subject='subject',
  Classroom='classroom',
  Document='Document',
  Documents='Documents',
  CreateDocument='Create Document',
  AllSubjects='All',
  File=' File',
  Files=' Files',
}

// Events

export enum EventsData{
  Events='Events',
  Event='Event',
  CreateEvent='Create Event',
}

// Feed

export enum FeedData{
  Report='Report',
  ThanksForReporting='Your report has been submitted.',
  ReportPost="A member of the organization will review the post to ensure it adheres to community guidelines. Thank you for helping to keep your classroom a safe and positive space.",
  Ok='Ok',
  Home='Home',
  WriteOnBoard='Write On Board…',
  Post='POST',
  Edit='Edit',
  Delete='Delete',
  StartDate='Start Date',
  EndDate='End Date',
  CreateShare='Create & Share',
} 

// Feed details

export enum FeedDetailsData{
  PostUnavailable='Post unavailable',
  Post='Post',
}

// Find classRoom

export enum FindClassRoomData{
  FindClassroom='Find Classroom',
  Post='Post',
}

// Home

export enum HomeData{
  SelectYour='Select your',
  Classroom='Classroom',
  Preference='Preference',
  FindClassroom=' Find Classroom',
  CreateClassroom='Create Classroom',
  NoClassRoom='Sorry, No classroom found!'
}

// Login

export enum LoginData{
  CollaborationEasy='Collaboration Made Super Easy !',
  FirstNameRequired='First Name is required',
  LastNameRequired='Last Name is required',
  CreateAccount='Create Account',
  ChangeName='Change Name',
  Signup='Sign up',
  EmailRequired='Email is required',
  EmailNotValid='Email is not valid',
  ForgotPassword='ForgotPassword',
  ForgotYour='Forgot your',
  PasswordQn='password?',
  SendOTP='Send OTP',
  OtpRequired='6 digit otp is required',
  ErrorSubmittingOTP='Error submitting OTP:',
  EnterYourCode='Enter your code',
  ResendOTPIn='Resend OTP in',
  Seconds='seconds',
  DidntReceiveCode="Didn't receive a code?",
  ResendOTP='Resend OTP',
  VerifyOTP=' Verify OTP',
  Required='* Required',
  PleaseEnterValidNumber='Please enter a valid phone number',
  EnterYour='Enter your',
  PhoneNumber='Phone Number',
  Continue='Continue',
  PasswordErrorValied='Password must be at least 6 characters with 1 uppercase 1 lowercase 1 special char and a number',
  PasswordsDoNotMatch='Passwords do not match',
  ResetPassword='Reset password',
  PleaseEnterNewPassword='Please enter new password',
  SignUp='SIGN UP',
  BackToLogin='Back to Login',
  PasswordRequired='Password is required',
  WelcomeBack='Welcome Back ,',
  PleaseEnterDetails='Please enter the details',
  ForgotPasswordQn='Forgot password ?',
  Login='LOGIN',
  DontHaveAccount="Don't have the account?",
  SignUpLwr='Sign up',
  Or='or',
  ContinueWithPhone='Continue with Phone',
  FirstName='firstName',
  LastName='lastName',
  MaximumLengthIs='Maximum length is',
  Characters='characters',
  LoginLwr='Login',
  HaveAnAccount ='Have an account? '
} 

// My Activities

export enum MyActivitiesData{
  MyActivities='My Activities'
}

// Note Details

export enum NoteDetailsData{
  Subject='Subject:',
  CreatedBy='Created by:',
  NoteUnavailable='Note unavailable'
}

// Notes

export enum NotesData{
  Subject='subject',
  Classroom='classroom',
  Notes='Notes',
  CreateNote='Create Note',
  AllSubjects='All',
}

// Notifications

export enum NotificationsData{
  All='All',
  Notifications='Notifications'
}

// QrCode

export enum QrCodeData{
  Classteam='Classteam',
  ScanQrCode='Scan QR Code to join classroom',
  ErrorQRCode='Error sharing QR code:',
  QRCode='QR Code',
  Class='class',
  Team='team',
  ShareClassroomCode='Share classroom code'
}

// Recent Activities

export enum RecentActivitiesData{
  NoNotifications='No Notifications'
}

// Report A Problem

export enum ReportAProblemData{
  WithFile='withFile',
  WithoutFile='withoutFile',
  Description='Description',
  Images='Images',
  Files='files',
  File='file',
  Report='Report',
  ReportProblem='Report a Problem'
}

// Set Privacy

export enum SetPrivacyData{
  Private='private',
  SelectPrivacyType='Select Privacy Type *',
  Public='public',
  PublicUpCase='Public',
  PrivateUpCase='Private',
  Privacy='privacy',
  Optional='(optional)',
  AboutClassroom='About Classroom',
  Finish='Finish'
}

// Settings

export enum AccountSettingsData{
  HelpSupport='Help & Support',
  Link ='https://classteam.io/help-and-support.html',
  _blank='_blank',
  Settings='Settings'
}

// Subjects

export enum SubjectsData{
  Subjects='Subjects',
  CreateSubject='Create Subject',
  Note='Note',
  Notes='Notes',
  Document='Document',
  Documents='Documents',
  Session='Session',
  Sessions='Sessions',
}

// Template

export enum TemplateData{
  NoResultFound='No result found!',
  All='All',
  SelectYour='Select your',
  Skip='Skip',
  BoardUniversity='Board/University',
  Next='Next'
}

// Timetable

export enum TimetableData{
  CurrentTimeFormat='ddd',
  Subject='Subject',
  start='start',
  end='end',
  StartTimeMustBeBeforeEndTime='Start time must be before end time',
  EndTimeMustBeAfterStartTime='End time must be after start time',
  EditTimetable='Edit Timetable',
  EditTimingsSessions='Edit Timings & Sessions',
  AddSubjects='Add Subjects +',
  Timing='Timing',
  Cancel='Cancel',
  Update='Update',
  DaysAndSessions='Days and Sessions',
  NumberOfColumnsDays='Number of Columns (Days)',
  NumberOfRowSession=' Number of Row (Session)',
  Confirm='Confirm',
  ByChangingSessions='By changing sessions the whole timetable data will wipe out.'
}

// User Profile

export enum UserProfileData{
  Classrooms='Classrooms',
  LastActivity='Last Activity'
}

// Select University

export enum SelectUniversityData{
  FindYourUniversity='Find your university/organisation',
  Placeholder='Type your university/organisation name',
  NoDataFound='Sorry, No university found!'
}

// Create post Modal

export enum CreatePostModalData{
  CreatePoll='Create Poll'
}

// Crop Picker

export const aspectData = [
  { title: 'Crop', value: 0 },
  { title: 'Square', value: 1 },
  { title: '3:2', value: 3 / 2 },
  { title: '5:3', value: 5 / 3 },
  { title: '4:3', value: 4 / 3 },
  { title: '5:4', value: 5 / 4 },
  { title: '7:5', value: 7 / 5 },
  { title: '16:9', value: 16 / 9 },
  { title: '9:16', value: 9 / 16 },
]

// Create Note Modal
export enum CreateNoteModalData{
  Document='Document',
  MaximumLengthExceeds='Maximum length exceeds',
  None='none',
  Empty='',
  Updated='updated',
  Created='created',
  Edit='Edit',
  Create='Create',
  MaxFilesLimitExceeded='* Max limit of 10 files exceeded.',
  MaxLimitFilesExceeded='Max limit of 10 files exceeded.',
  Name='Name',
  SelectSubject='Select subject',
  SelectSubjectOptional='Select subject (optional)',
  Nones='None',
  NoSubjectAddedYet='No Subject added yet.',
  ClickHere='Click here',
  ToCreateSubject='to Create Subject',
  Files='files',
  AddFile='Add file',
  Update='Update',
  Subject='Subject',
  UploadFiles='Upload files'
}

export enum ExamsData{
  Exams='Exams',
}

export const dummyExamData:any = [
  {
    _id: '1',
    name: 'Math Exam',
    subject: 'Class A',
    startDate: '2023-10-01',
    endDate: '2023-10-10',
    noOfQns:10,
    duration:30
  },
  {
    _id: '2',
    name: 'Science Exam',
    subject: 'Class B',
    startDate: '2023-11-01',
    endDate: '2023-11-10',
    noOfQns:20,
    duration:60
  },
  {
    _id: '3',
    name: 'History Exam',
    subject: 'Class C',
    startDate: '2023-12-01',
    endDate: '2023-12-10',
    noOfQns:30,
    duration:120
  },
  {
    _id: '4',
    name: 'Geography Exam',
    subject: 'Class D',
    startDate: '2023-12-15',
    endDate: '2023-12-20',
    noOfQns:45,
    duration:150
  },
  {
    _id: '5',
    name: 'Physics Exam',
    subject: 'Class E',
    startDate: '2024-01-05',
    endDate: '2024-01-15',
    noOfQns:5,
    duration:10
  },
  {
    _id: '6',
    name: 'Chemistry Exam',
    startDate: '2024-02-01',
    endDate: '2024-02-10',
    noOfQns:15,
    duration:30
  },
  {
    _id: '7',
    name: 'Biology Exam',
    subject: 'Class G',
    startDate: '2024-03-01',
    endDate: '2024-03-10',
    noOfQns:10,
    duration:30
  },
  {
    _id: '8',
    name: 'English Exam',
    subject: 'Class H',
    startDate: '2024-04-01',
    endDate: '2024-04-10',
    noOfQns:10,
    duration:30
  },
];

export enum AttendenceData{
  SelectSession='Select Session',
  SelectSubject='Select Subject',
  None='none',
  Empty='',
  Students='Students'
}

export enum StudentAttendenceData{
  YourAttendence='Your Attendance',
  AttendenceOverview='Attendance Overview',
  Attended='Attended',
  Missed='Absent',
  Total='Total',
  Required='Required attendance: 70%'
}




