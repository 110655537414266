import React from 'react';
import './index.scss';
import { ProfileCard } from 'assets/styles/commonStyle';
import { Avatar } from '@mui/material';
import { colors } from 'assets/styles/colors';

interface Props {
    data?:any
}

const MeetParticipants: React.FC<Props> = (props) => {
    const {data}=props
  return (
    <div className='meet_participants'>
      <h1 className='_participants'>Participants ({data?.length})</h1>
      {data?.map((item: any, index: number) => {
                return (
                    <div className='__card_d'>
                        <ProfileCard style={{ alignItems: 'center', gridTemplateColumns: '45px 1fr auto' }}>
                            <Avatar
                                src={item?.profileImage}
                                sx={{ bgcolor: '#575757', width: 34, height: 34, fontSize: '14px',border:index==0?`2px solid ${colors.primary}`:'none' }}
                                aria-label="recipe"
                            >
                                {item?.firstName?.slice(0, 1)}
                                {item?.lastName?.slice(0, 1)}
                            </Avatar>
                            <div className="___label">
                                <div className="name">
                                    {item?.id == 1 ?
                                        <h1 className='text active'>You</h1> :
                                        <h1 className='text'>{item?.name}</h1>}
                                </div>
                            </div>
                        </ProfileCard>
                    </div>
                )
            })}
    </div>
  );
};

export default MeetParticipants;