// Core
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

// Third-Party Libraries
import { Button, TextField, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Utilities
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
import {
  LoginProps,
  SignInFormErrorState,
  SignInFormState,
  SignInInitialError,
  SignInInitialForm
} from "utils/types";
import {
  CursorType,
  InputLabels,
  InputMargins,
  InputNames,
  InputTypes,
  InputVariants,
  LoginData,
  VisibilityTypes
} from "utils/constants";

// Components
import PrimaryButton from "../../../components/atoms/CustomButtons";

// Redux Actions and State
import { setUserDetails } from "../../../redux/slices/setUser/userSlice";

// Styles and Constant
import { colors } from "assets/styles/colors";

// Assets
import { loadOrganizations } from "utils/commonUtils";

const SignInForm: React.FC<LoginProps> = ({ setType,university }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organizations = loadOrganizations();
  const [formData, setFormData] = useState<SignInFormState>(SignInInitialForm);
  const [errors, setErrors] = useState<SignInFormErrorState>(SignInInitialError);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value.trim() });
  };

  const validateForm = (): boolean => {
    let newErrors = { ...SignInInitialError };

    if (!formData.email) {
      newErrors.email = LoginData.EmailRequired;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = LoginData.EmailNotValid;
    }

    if (!formData.password) {
      newErrors.password = LoginData.PasswordRequired;
    }
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === '');
  };

  const handleSignup = async () => {
    setIsLoading(true)
    const res = await fetchData(restAPIs.getSignIn({...formData,organizationId:organizations?._id}), navigate);
    setIsLoading(false)
    const data = res.data
    if (res.statusCode === 200) {
      dispatch(setUserDetails(data))
      navigate('/home', { replace: true })
    }
    else {
      setErrors({ message: data?.message, ...data?.fields })
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      handleSignup()
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
      if (!organizations) {
        navigate('/select-university');
      }
    }, [organizations]);
  
    if(!organizations) {
      return null;
    }

  return (
    <div className="signup-form-wrapper">
      <img src={university?.logo} className='login-university-logo' alt="" />
      <Typography variant="h1">{university?.orgName}</Typography>
      {/* <Typography fontWeight={400} variant="h6">{LoginData.PleaseEnterDetails}</Typography> */}
      <form onSubmit={(e) => handleSubmit(e)} className='login-form'>
        <TextField
          label={InputLabels.Email}
          fullWidth
          variant={InputVariants.Outlined}
          error={!!errors.email}
          helperText={errors.email}
          value={formData.email}
          onChange={handleChange}
          margin={InputMargins.Normal}
          name={InputNames.Email}
          className="input-field"
          InputLabelProps={{
            style: {
              fontSize: '13px',
              fontWeight: 400,
              color: colors.inputLabelColor
            },
          }}
        />
        <TextField
          label={InputLabels.Password}
          type={showPassword ? InputTypes.Text : InputTypes.Password}
          name={InputNames.Password}
          fullWidth
          error={!!errors.password}
          helperText={errors.password}
          value={formData.password}
          variant={InputVariants.Outlined}
          onChange={handleChange}
          margin={InputMargins.Normal}
          InputLabelProps={{
            style: {
              fontSize: '13px',
              fontWeight: 400,
              color: colors.inputLabelColor
            },
          }}
          className="input-field"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div className="hide-eye-icon" onClick={handleClickShowPassword}>
                  {showPassword ?
                    <VisibilityOff
                      sx={{ color: colors.inputLabelColor, visibility: formData.password?.length > 0 ? VisibilityTypes.Visible : VisibilityTypes.Hidden }} /> :
                    <Visibility
                      sx={{ color: colors.inputLabelColor, visibility: formData.password?.length > 0 ? VisibilityTypes.Visible : VisibilityTypes.Hidden }} />}
                </div>
              </InputAdornment>
            ),
          }}
        />
        <Typography
          onClick={() => setType('forgetPassword')}
          variant="h6"
          sx={{
            width: '100%',
            marginBottom: '25px',
            cursor: CursorType.Pointer
          }}
          fontWeight={'bold'}
          fontSize={'13px'}
          align="right">
          {LoginData.ForgotPasswordQn}
        </Typography>
        <PrimaryButton
          fullWidth={false}
          isLoading={isLoading} >
          {LoginData.Login}
        </PrimaryButton>
        {errors?.message &&
          !errors?.password &&
          !errors?.email &&
          <span
            style={{
              color: 'red',
              margin: '10px 0',
              fontSize: '13px'
            }}>
            {errors?.message}
          </span>}
      </form>
    </div >
  );
};

export default SignInForm;
