// Core
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import GridContainer from 'components/molecules/GridContainer';
import UserCard from 'components/atoms/UserCard';
import NoteFiles from 'components/atoms/NoteFiles';
import NoNotes from 'components/atoms/NoNotes';
import { CreateButton } from 'components/atoms/CustomButtons';
import CreateNoteModal from 'components/atoms/CreateNoteModal';
import ListActionMenu from 'components/atoms/ListActionMenu';
import { useSnackbar } from 'components/atoms/SnackbarProvider';
import CommonDeleteModal from 'components/atoms/CommonDeleteModal';
import DeleteModal from 'components/atoms/DeleteModal';

// Third-Party Libraries
import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import _ from 'lodash';
import Tooltip from '@mui/material/Tooltip';

// Styles and Constants
import './index.scss'
import { colors } from 'assets/styles/colors';

// Utilities
import { useCheckRole, useFetchClassRoomItems } from 'utils/customHooks';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import { capitalizeEachWord, getObjectById, loadActiveClassroomLocalStorage } from 'utils/commonUtils';
import {
    CreateTypes,
    DocumentDetailsDeleteModal,
    DocumentsData,
    MODAL_STYTLE,
    NotesData,
    restrictModalData,
    SnackBarMessages,
    StatusType
} from 'utils/constants';
import { setDeleteNote } from '../../redux/slices/setClassroom/classRoomSlice';


const Notes: React.FC = () => {

    const { classroomData } = useSelector((state: any) => state.classRoom) || {};
    const activeClassroom = loadActiveClassroomLocalStorage()

    const { showSnackbar } = useSnackbar();
    const { isInstructor } = useCheckRole()
    const { isLastNote, isNoteLoading } = classroomData
    const subjects = classroomData?.subjectList
    const { subject, refId } = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selected, setSelected] = useState<number>(0)
    const [selectedFilter, setSelectedFilter] = useState<any>(null)
    const [notes, setNotes] = useState<any>([]);
    const [page, setPage] = useState<number>(2)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false)
    const [isLastPage, setIsLastPage] = useState<boolean>()
    const [createType, setCreateType] = useState<any>('');
    const [editItem, setEditItem] = useState<any>(null);
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [isRestricted, setRestricted] = useState(false);
    const { fetchMetaData } = useFetchClassRoomItems()

    const tempSubject = getObjectById(subjects, selectedFilter?._id)
    const loadMore = async (initialPage?: boolean) => {
        if (!isLastPage || initialPage) {
            let params = {
                classRoomId: activeClassroom?._id,
                page: initialPage ? 1 : page,
                limit: 10,
            };
            let filterparams = {
                subjectId: selectedFilter?._id,
                page: initialPage ? 1 : page,
                limit: 10,
                classRoomId: activeClassroom?._id
            };
            setIsLoading(true)
            const res = refId ?
                await fetchData(restAPIs.getNoteItem(refId), navigate) :
                await fetchData(restAPIs.getNotes(selectedFilter?._id ? filterparams : params), navigate)
            setIsLoading(false)
            if (res?.statusCode === 200 && res?.data) {
                const temp = res.data?.notes ?? []
                if (refId) {
                    setNotes([res.data])
                }
                else {
                    setNotes(initialPage ? temp : notes.concat(temp))
                }
                setIsLastPage(res.data.isLastPage)
                setPage(initialPage ? 2 : page + 1)
            }
        }
    }

    const onDelete = async () => {
        setIsDeleteLoading(true)
        setDeleteModal(false)
        const res = await fetchData(restAPIs.getNotesDelete(notes[selected]?._id), navigate)
        if (res.statusCode == 200) {
            let temp = [...notes]
            temp.splice(selected, 1)
            setNotes(temp)
            dispatch(setDeleteNote(notes[selected]?._id))
            showSnackbar(SnackBarMessages.NoteDeletedSuccessfully, StatusType.success)
            fetchMetaData()
            setIsDeleteLoading(false)
        }
        else {
            setIsDeleteLoading(false)
            showSnackbar(SnackBarMessages.SomethingWentWrong, StatusType.error)
        }
    }
    const onEdit = () => {
        setEditItem(notes[selected])
        setCreateType(CreateTypes.Note)
    }

    const onCreateNew = () => {
        if (activeClassroom?.memberPermissions?.academic || isInstructor()) {
            setEditItem(null)
            setCreateType(CreateTypes.Note)
        }
        else setRestricted(true)
    }

    useEffect(() => {
        if (selectedFilter) {
            setNotes([])
            loadMore(true)
            setIsLastPage(false)
        }
        else if (!subject && !refId) {
            setNotes(classroomData.notesList)
        }
    }, [selectedFilter]);

    useEffect(() => {
        setIsLastPage(isLastNote)
    }, [isLastNote])

    useEffect(() => {
        if (!subject && !refId) {
            setIsLoading(false)
            setNotes(classroomData.notesList)
            setSelectedFilter(null)
            setPage(2)
            setIsLastPage(classroomData.isLastNote)
        }
    }, [classroomData.notesList]);

    useEffect(() => {
        if (subject)
            setSelectedFilter({ _id: subject })
        else
            setSelectedFilter(null)
        if (refId) {
            setNotes([])
            loadMore(true)
        }
    }, [subject, refId]);

    const onBack = () => {
        navigate(-1)
    }

    const onClick = (item: any) => {
        if (item === null) {
            navigate('/notes')
            setIsLastPage(false)
            setSelectedFilter(item)
        }
        else {
            setSelectedFilter(item)
        }
    }
    const onClose = () => {
        setEditItem(null)
        setCreateType('')
    }

    return (
        <GridContainer
            loadMore={loadMore}
            pageTitle={!refId && NotesData.Notes}
            data={notes}
            loading={isLoading}
            page={page}
            right={<NoteFiles notes={notes[selected]} setNotes={setNotes} selected={selected} notesArray={notes} />}
            leftGridPartition={1 / 2}>
            {!refId &&
                <div className='create-button-wrapper'>
                    {isInstructor() ?
                        <CreateButton bgColor={colors.createButtonColor} onClick={onCreateNew}>
                            {NotesData.CreateNote}
                        </CreateButton> : <div />}
                    {subjects?.length > 0 &&
                        <FormControl sx={{ minWidth: 120, outline: 'none', fontSize: '13px' }} size="small">
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={selectedFilter?._id ?? ''}
                                displayEmpty
                                className='__select'
                            >
                                <MenuItem value={''} onClick={() => onClick(null)}>{NotesData.AllSubjects}</MenuItem>
                                {subjects?.map((item: any) => (
                                    <MenuItem value={item?._id} onClick={() => onClick(item)}>{item.subjectName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>}
                </div>}
            <div style={{ marginBottom: '20vh' }}>
                {notes?.map((item: any, index: number) => {
                    const fileCount = item?.files?.length
                    return (
                        <div onClick={() => setSelected(index)} className={`notes-container ${selected == index ? 'selected' : ''}`}>
                            <div className="title-note-container">
                                <text className='note-files-name'>{capitalizeEachWord(item?.title)}</text>
                                {(index === selected && isDeleteLoading) ?
                                    <CircularProgress size={20} sx={{ color: colors?.primary }} /> :
                                    <ListActionMenu item={item} onEdit={onEdit} onDelete={() => setDeleteModal(true)} />}
                            </div>
                            <Typography variant='h6' fontWeight={400} fontSize={'12px'} style={{ wordWrap: 'break-word' }} key={index}>
                                {item.description}
                            </Typography>
                            <div className='notes-sub-container'>
                                {item?.subjectName?.length > 0 &&
                                    <Tooltip title={item?.subjectName}>
                                        <div className='notes-sub-name'>
                                            {item?.subjectName}
                                        </div></Tooltip>}
                                <div className='notes-count-name'>
                                    {fileCount} {fileCount == 1 ? DocumentsData.File : DocumentsData.Files}
                                </div>
                            </div>
                            <div className="notes-user-card-wrapper">
                                <UserCard height={32} width={32} item={item} h1FontSize={'11px'} pFontSize={'10px'} />
                            </div>
                            <div className={`${selected == index ? 'dropdown-menu' : 'hide-dropdown-menu'}`} >
                                <NoteFiles notes={notes[selected]} />
                            </div>
                            <div className='line-separator' />
                        </div>
                    );
                })}
                <CreateNoteModal
                    open={createType == CreateTypes.Note}
                    editItem={editItem}
                    loadMore={loadMore}
                    subject={tempSubject}
                    createType={createType}
                    modalStyle={MODAL_STYTLE}
                    handleClose={onClose} />
                {isLoading && !isLastPage &&
                    <div className='loader-container'>
                        <CircularProgress size={20} sx={{ color: colors?.primary }} />
                    </div>}
                {notes?.length == 0 && !isLoading &&
                    <NoNotes type={NotesData.Notes} />}
                <CommonDeleteModal
                    open={deleteModal}
                    buttonLabel={DocumentDetailsDeleteModal.YesDelete}
                    description={DocumentDetailsDeleteModal.Desc}
                    title={DocumentDetailsDeleteModal.DeleteTitle}
                    handleClose={() => setDeleteModal(false)}
                    handleSubmit={() => onDelete()}
                />
                <DeleteModal
                    open={isRestricted}
                    onSubmit={() => setRestricted(false)}
                    data={restrictModalData}
                    modalStyle={MODAL_STYTLE}
                    handleClose={() => setRestricted(false)}
                />
            </div>
        </GridContainer>
    )
};

export default Notes
