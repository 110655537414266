import 'swiper/css';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import './index.scss'
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import { useNavigate } from 'react-router-dom';
import { loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';
import { useCheckRole, useFetchClassRoomItems } from 'utils/customHooks';

const QuickAccess = () => {

    const navigate = useNavigate()
        const { isInstructor } = useCheckRole()
            const userObject = loadUserFromLocalStorage();
            const user = userObject?.userDetails
    const { fetchSubject,fetchNotes,fetchDocuments,fetchTodaysEvents,fetchEvents } = useFetchClassRoomItems()

    const [metadata, setMetadata] = useState<any>(null);
    const [isLoading, setLoading] = useState<any>(false);
    const activeClassroom = loadActiveClassroomLocalStorage()

    const attendence=isInstructor()? 'add':`${user?.attendance?.attendancePercentage?.toFixed(2)}%`

    useEffect(() => {
        fetchMetaData();
    }, []);

    const fetchMetaData = async () => {
        if (activeClassroom) {
            setLoading(true)
            const res = await fetchData(restAPIs.getMetaData(activeClassroom?._id),navigate)
            if (res.statusCode === 200 && res.data) {
                setMetadata(res?.data)
            }
            setLoading(false)
        }
    }

    const data = [
        {
            iconName: 'fi fi-rr-memo-pad',
            title: 'Notes',
            subTitle: metadata?.notesCount ?? '0',
            screen: '/notes',
        },
        {
            iconName: 'fi fi-rr-user',
            title: 'Attendance',
            subTitle:attendence,
            screen: isInstructor()?'/attendance':'/studentAttendance',
        },
        {
            iconName: 'fi fi-rr-duplicate',
            title: 'Documents',
            subTitle: metadata?.documentCount ?? '0',
            screen: '/documents',
        },
        {
            iconName: 'fi fi-rr-book-copy',
            title: 'Subjects',
            subTitle: metadata?.subjects ?? '0',
            screen: '/subjects',
        },
        {
            iconName: 'fi fi-rr-book-alt',
            title: 'Curriculum',
            subTitle: metadata?.curriculums?? '0',
            screen: '/curriculum',
        },
        {
            iconName: 'fi fi-rr-party-horn',
            title: 'Events',
            subTitle: metadata?.eventsCount ?? '0',
            screen: '/events',
        },
    ];

    const onClick = (item: any) => {
        switch (item?.screen) {
            case data[0].screen:
                fetchNotes(activeClassroom?._id)
                break;
            case data[2].screen:
                fetchDocuments(activeClassroom?._id)
                break;
            case data[3].screen:
                fetchSubject(activeClassroom?._id)
                break;
            case data[5].screen:
                fetchTodaysEvents(activeClassroom?._id)
                fetchEvents(activeClassroom?._id)
                break;
        }
        navigate(item?.screen)
    }
    
    return (
        <div style={{ padding: '0 20px' }}>
            <Typography style={style} variant="h3" className='home-form-title' fontSize={15}>
                My Classroom
            </Typography>
            <div className='card-container'>
                {data.map((item,index) => (
                    <div onClick={() => onClick(item)} key={item.title} className="quick-access-card">
                        <div className="quick-access-img-container">
                            <i className={item?.iconName}></i>
                            <Typography fontWeight={'normal'} variant='h6'>{item.title}</Typography>
                        </div>
                        <div className="card-content">
                            <Typography variant='h1' style={{marginTop:index==1?8:0}} fontSize={index==1?16:24}>{isLoading ? '-' : item.subTitle}</Typography>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default QuickAccess;

const style = {
    marginBottom: '15px'
}

