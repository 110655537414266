import * as React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number,size?: number; color?: string,fontSize?:number },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress  variant="determinate" size={120}  {...props} style={{borderRadius:'10px',color:props?.value>=70?'#4DCC52':'#FF0000'}} thickness={3.4} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="secondary"
          fontSize={24}
        >{`${props?.value?.toFixed(2)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function AttendenceProgress({progress}:any) {
  return <CircularProgressWithLabel value={progress<=100?progress:100} sx={{
    '& circle': {
      strokeLinecap: 'round', // Rounded stroke for circular progress
    },
  }}/>;
}
